(function ($) {
  $('.uk-section-business-cases .uk-card-business-case-link-desc').on('click', function(){
    $('.uk-section-business-cases .uk-card-business-case-link-desc').removeClass('uk-active');
    $(this).addClass('uk-active');
    $('.uk-section-business-cases .uk-card-tab-item').hide();

    var activeTab = $(this).attr('data-bctab');
    $('#'+activeTab).fadeIn();
    return false;
  });
})(jQuery);
